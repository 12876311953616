.notification-box {
  /* position: fixed; */
  /* z-index: 99; */
  /* top: 30px; */
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: #b9ed2e;
  padding-top: 6%;
}
.notification-bell-animation {
  animation: bell 3s 3s both infinite;
}
.notification-bell * {
  display: block;
  margin: 0 auto;
  background-color: #3f5b58;
  /* box-shadow: 0px 0px 15px #3f5b58; */
}
.bell-top {
  width: 2px;
  height: 2px;
  border-radius: 3px 3px 0 0;
}
.bell-middle {
  width: 12px;
  height: 12px;
  margin-top: -1px;
  border-radius: 12.5px 12.5px 0 0;
}
.bell-bottom {
  position: relative;
  z-index: 0;
  width: 17px;
  height: 2px;
}
.bell-bottom::before,
.bell-bottom::after {
  content: "";
  position: absolute;
  top: -4px;
}
.bell-bottom::before {
  left: 1px;
  border-bottom: 4px solid #3f5b58;
  border-right: 0 solid transparent;
  border-left: 4px solid transparent;
}
.bell-bottom::after {
  right: 1px;
  border-bottom: 4px solid #3f5b58;
  border-right: 4px solid transparent;
  border-left: 0 solid transparent;
}
.bell-rad {
  width: 3px;
  height: 2px;
  margin-top: 2px;
  border-radius: 0 0 4px 4px;
}

.bell-rad-animation {
  animation: rad 3s 3s both infinite;
}

.notification-count {
  position: absolute;
  z-index: 1;
  /* top: -3px;
  right: 0px; */
  width: 12px;
  height: 12px;
  line-height: 13px;
  font-size: 10px;
  border-radius: 50%;
  background-color: #fff;
  color: #3f5b58;
  /* animation: zoom 3s 3s both infinite; */
}
@keyframes bell {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(30deg);
  }
  20% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(0);
  }
  90% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes rad {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(6px);
  }
  20% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.notification-attachment__wrapper {
  max-width: 10%;
  width: 100%;
}

.notification-attachment {
  height: 65px !important;
  width: 150px;
  object-fit: cover;
}

.download-btn__attachment {
  background: #ffffff;
  border: solid 1px #e6e6e6;
  border-radius: 2px;
  display: inline-block;
  height: 90%;
  line-height: 100px;
  margin: 5px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.download-btn__attachment span {
  background: #f2594b;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  padding: 5px 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

.download-btn__attachment span:last-child {
  margin-left: -20px;
}

.download-btn__attachment:before,
.download-btn__attachment:after {
  background: #ffffff;
  border: solid 3px #9fb4cc;
  border-radius: 4px;
  content: "";
  display: block;
  height: 35px;
  left: 50%;
  margin: -17px 0 0 -12px;
  position: absolute;
  top: 50%;
  /*transform:translate(-50%,-50%);*/

  width: 25px;
}

.download-btn__attachment:hover:before,
.download-btn__attachment:hover:after {
  background: #e2e8f0;
}
/*a:before{transform:translate(-30%,-60%);}*/

.download-btn__attachment:before {
  margin: -23px 0 0 -5px;
}

.download-btn__attachment:hover {
  background: #e2e8f0;
  border-color: #9fb4cc;
}

.download-btn__attachment:active {
  background: #dae0e8;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.25);
}

.download-btn__attachment span:first-child {
  display: none;
}

.download-btn__attachment:hover span:first-child {
  display: inline-block;
}

.download-btn__attachment:hover span:last-child {
  display: none;
}
